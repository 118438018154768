<template>
  <Wave />
  <main class="main">
    <Section heading-id="seat-search" title="桌位查詢" direction="column" style="padding-top: 120px;">
      <template #content>
        <FormRow>
          <FormLabel label-for="tel">電話末三碼</FormLabel>
          <TextInput
            name="tel"
            type="number"
            v-model:value="form.tel"
            :placeholder="`123`"
            autocomplete="off"
            pattern="[0-9]*"
            inputmode="numeric"
          />
        </FormRow>
      </template>
    </Section>
    <Section heading-id="result" title="您的桌位">
      <template #content>
        <div class="result">
          <template v-if="Array.isArray(result) && result.length > 0">
            <SeatResult
              v-for="(person, idx) in result"
              :key="`${person.name}${idx}`"
              :name="person.name"
              :seat="person.seat"
            />
          </template>
          <template v-else>
            {{ result }}
          </template>
        </div>
      </template>
    </Section>
  </main>
  <Wave reverse />
</template>

<script>
import {
  computed,
  defineAsyncComponent,
  reactive,
  onMounted,
} from 'vue';
import * as Papa from 'papaparse';

export default {
  components: {
    FormLabel: defineAsyncComponent(() => import('@/components/inputs/FormLabel.vue')),
    FormRow: defineAsyncComponent(() => import('@/components/inputs/FormRow.vue')),
    Section: defineAsyncComponent(() => import('@/components/Section.vue')),
    SeatResult: defineAsyncComponent(() => import('@/components/SeatResult.vue')),
    TextInput: defineAsyncComponent(() => import('@/components/inputs/TextInput.vue')),
    Wave: defineAsyncComponent(() => import('@/components/Wave.vue')),
  },
  setup() {
    const form = reactive({
      tel: '',
    });
    let seatsData = reactive([]);

    const RESULT_NOT_FOUND = '找不到您的桌位，請找工作人員協助';
    const RESULT_PLACEHOLDER = '請輸入電話末三碼';
    const INDEX_NAME = 0;
    const INDEX_TEL = 1;
    const INDEX_SEAT = 2;

    const getSeat = (seatsData, tel) => {
      if(tel === '') return '';
      if(tel.length < 3) return RESULT_PLACEHOLDER;

      const remain = seatsData.filter(item => item[INDEX_TEL] === tel);

      if(remain.length === 0) {
        return RESULT_NOT_FOUND;
      }

      return remain.map(item => ({
        name: item[INDEX_NAME],
        seat: `${ item[INDEX_SEAT] } 桌`,
      }));
    };

    const result = computed(() => {
      if(!form.tel) return RESULT_PLACEHOLDER;

      return getSeat(seatsData, form.tel);
    });

    onMounted(async () => {
      try {
        const target = '/seats.csv';
        const res = await fetch(target, {
          method: 'get',
          headers: {
            'content-type': 'text/csv;charset=UTF-8',
          },
        });
        if(res.status === 200) {
          const csv = await res.text();
          seatsData = Papa.parse(csv).data;
          console.log(seatsData);
        }
      } catch (err) {
        console.error(err);
      }
    });

    return {
      form,
      result,
      seatsData,
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 100vh;
}
.result {
  font-size: 4rem;
}
</style>
